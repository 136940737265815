.ca-form-field {
  width: 100%;
  &--read-only {
    display: inline-block;
    width: auto;
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }
  &__accept {
    margin-right: 13px;
  }
  &__close {
    padding: 5px;
  }
  &__button {
    color: #594bdb;
    cursor: pointer;
    border-radius: 100px;
    background-color: #f2f2f2;
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
