.ca-focus-area {
  &__title {
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    color: #242424;
    font-family: 'Poppins', sans-serif;
    color: #201b50;
    padding: 25px 0px 5px 0px;
    line-height: 1;
  }
  &--hidden {
    display: none;
  }
  &--show {
    display: block;
  }
}
