.ca-feed__tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    border-bottom: 1px solid #CECECE;

    > p {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #201B50;
        font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    .addButton {
        padding-right: 5px;
        border-radius: 4px;
        border: 1px solid #594BDB;
        height: 24px;
        -webkit-appearance: none;
        display: flex;

        span {
            font-size: 20px;
            color: #594BDB;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        select {
            cursor: pointer;
            border: none;
            outline: none;
            border-radius: 4px;
            padding: 5px;
            width: 75px;
            background: transparent;
            color: #594BDB;
            font-family: 'Poppins', sans-serif;
            font-size: 10px;
            font-weight: 500;
            -webkit-appearance: none;
            -moz-appearance: none;
        }
    }

    .tabs {
        display: flex;
        gap: 8px;


        .tabItem {
            padding: 4px 8px;
            text-transform: capitalize;
            cursor: pointer;
            font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: #858585;
            transition: all .3s;
        }
        .tabItem.active {
            background-color: #E3E3E3;
            border-radius: 4px 4px 0px 0px;
            color: #201B50;
        }
    }
}

.ca-feed__feedlist {
    margin-top: 11px;
    max-height: 200px;
    overflow: auto;
    .listitem {
        border-top: 1px solid #C0C0C0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .single-update {
            background: inherit;
            transition: all .2s;
        }
        .editIcon {
            display: none;
            transition: all .2s;
        }
        .single-update:hover {
            background: #F2F2F2;
            .editIcon {
                display: block;
            }
        }
        
        .date {
            font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 8px;
            line-height: 10px;
            color: #484378;
            position: absolute;
            top: -5px;
            left: calc(50% - 2rem);
            background: white;
            padding: 0 1em;
        }

        .content {
            display: flex;
            justify-content: space-between;
            padding: 11px 4px;

            .name {
                font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #201B50;
                margin: 0;

                .template {
                    color: #C0C0C0;
                    padding-left: .5rem;
                }
            }

            .comment {
                font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #484378;
                padding: 0 0 0 4px;
                margin-bottom: 10px;
            }

            .customAttribute {
                display: flex;
                align-items: baseline;
                flex-direction: column;
                padding: 8px 0 0 4px;

                p {
                    margin: 0;
                    padding: 0;
                    font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 16px;
                }

                span {
                    color: #484378;
                    font-size: 12px;
                }
            }

            .update {
                padding: 8px;
                
                .question-group {

                    display: flex;
                    flex-direction: column;
                    gap: 2px;

                    .question {
                        margin: 0;
                        font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 10px;
                        line-height: 16px;
                        color: #3C3C3C;
                    }
    
                    .answer {
                        margin: 0;
                        font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        color: #484378;
                    }
                }

            }

        }

        .sideBox {
            display: flex;
            flex-direction: column;
            gap: 6px;
            align-items: flex-end;

            svg {
                cursor: pointer;
            }

            .time {
                font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 8px;
                line-height: 10px;
                padding: 0;
                margin: 0;
                color: #201B50;
            }
        }
    }
}


.ui-radiogroup__item__indicator {
    border: none !important;
}

.ca-feed__feedinput {
    position: relative;
    background: white;
    border-radius: 4px;

    > p {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #201B50;
        font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .dynamic-inputs {
        background: #f2f2f2;
        padding: 8px;
    }

    .input-container {

        display: flex;
        flex-direction: column;

        p {
            width: 100%;
        }
        
        input, select {
            border: 2px solid #CECECE;
            border-radius: 4px;
            height: 32px;
            background: white;
        }
    
        select {
            width: 260px;
        }


    }

    .checkbox {
        label {
            display: flex;
            align-items: center;
            gap: 12px;
            position: relative;
            cursor: pointer;

            .checkmark {
                position: absolute;
                top: 9px;
                left: 0;
                height: 14px;
                width: 14px;
                background-color: white;
                border: 2px #CECECE;
                border-radius: 2px;
            }
            .checkmark:after {
                left: 5px;
                top: 1px;
                width: 4px;
                height: 10px;
                border: solid white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                content: "";
                position: absolute;
            }

            input:checked ~ .checkmark {
                background-color: #201B50;
            }
        }
    }

    .radio {
        label {
            display: flex;
            align-items: center;
            gap: 12px;
            position: relative;
            cursor: pointer;

            .checkmark {
                position: absolute;
                top: 9px;
                left: 0;
                height: 14px;
                width: 14px;
                background-color: white;
                border-radius: 50%;
                border: 2px #CECECE;
            }
            .checkmark:after {
                top: 5px;
                left: 5px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: white;
                content: '';
                display: block;
                position: relative;
            }

            input:checked ~ .checkmark {
                background-color: #201B50;
            }
        }
    }

    .date input {
        width: 142px;
    }

    .custom-file-uploader {
        border: 1px solid #ccc;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
        margin: 8px 0;
        background: white;
        font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        transition: all .2s;
        color: #3C3C3C;
    }
    
    .custom-file-uploader:hover {
        color: white;
        background: #201B50;
    }

    .custom-file-uploader input {
        display: none;
    }
    
    
    .buttons {
        display: flex;
        justify-content: space-between;
        bottom: 8px;
        right: 5px;

        .ui-button {
            background: none;
            border: none;
            box-shadow: none;
            padding: 8px;
            margin: 20px 0 0 0;
            min-width: auto;
            font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #594BDB;
        }

        .ui-button:disabled {
            color: silver;
        }
    }
}