.ca-owner {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background-color: #594bdb;
  display: flex;
  align-items: center;
  justify-content: center;
  &__text {
    font-family: 'Poppins', sans-serif;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
  }
}
