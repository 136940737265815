.ca-goals {
  height: 100%;
  &__page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  &__filters-container {
    margin-top: 12px;
    margin-bottom: 16px;
  }
  .ui-table__header {
    .ui-table__cell__content {
      font-family: 'Poppins', sans-serif;
      color: #201b50;
      font-size: 11px;
      text-transform: capitalize;
    }
    .ui-table__cell {
      &::before {
        display: none;
      }
    }
  }
  .ui-table__cell {
    &::before {
      width: 3px;
      background-color: #dfdfdf;
      content: '';
      height: 30px;
      position: absolute;
      left: -2px;
      border-radius: 5px;
    }
  }
  &__goal-row {
    background-color: #f2f2f2 !important;
    border-radius: 5px 10px 10px 5px;
    // padding: 5px 10px !important;
    height: 45px !important;
    margin: 4px 0;
    position: relative;
    &::before {
      content: '';
      width: 7px;
      border-radius: 5px;
      height: 100%;
      position: absolute;
      left: 0;
    }
  }
  &__not-start {
    &::before {
      background-color: @statusNotStarted;
    }
  }
  &__overdue {
    &::before {
      background-color: @statusOverdue;
    }
  }
  &__on-track {
    &::before {
      background-color: @statusOnTrack;
    }
  }
  &__behind {
    &::before {
      background-color: @statusBehind;
    }
  }
  &__complete {
    &::before {
      background-color: @statusComplete;
    }
  }
  &__within-threshold {
    &::before {
      background-color: @statusWithinThreshold;
    }
  }
  &__manual {
    &::before {
      background-color: @statusManual;
    }
  }
  &__title {
    color: #201b50;
    font-size: 11px;
    display: block;
    text-align: left;
    cursor: pointer;
    padding-left: 11px;
    &:hover {
      color: #594bdb;
    }
  }
  &__text {
    font-size: 11px;
    display: block;
    font-weight: 600;
    color: #201b50;
  }
  &__width-title {
    &::before {
      display: none;
    }
  }
  &__width-type {
    max-width: 97px !important;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &__width-owner {
    max-width: 70px !important;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &__width-date {
    max-width: 120px !important;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &__width-status {
    max-width: 138px !important;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &__width-weight {
    max-width: 80px !important;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &__table-header-title {
    margin-top: 8px;
    margin-bottom: 8px;
    
  }
  &__table-header-title-mobile {
    cursor: pointer;
    
  }
  &__table-header-image-container {
    min-height: 24px;
    min-width: 24px;
    
  }
  &__table-header-image {
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }
  &__btn-sign-out {
    .ui-button__content {
      text-transform: initial;
    }
  }
}
