.ca-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  &__page-text {
    color: #242424;
    font-size: 14px;
  }
  &__page-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  &__page-button {
    width: 28px;
    height: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    margin: 0 3px;
    border: none;
    background-color: transparent;
    outline: none;
    padding: 3px;
    &:focus {
      outline: none;
    }
  }
  &__page-button-disabled {
    cursor: not-allowed;
    color: #dcdcdc;
    svg {
      path {
        &:last-child {
          fill: #dcdcdc;
        }
      }
    }
  }
}
