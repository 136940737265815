.ca-goal-create {
  &__goal-modal {
    box-shadow: none !important;
    width: 100% !important;
    height: 100% !important;
    padding-top: 0 !important;
    .ui-dialog__header {
      display: none;
    }
  }
}
