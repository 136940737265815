@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@1,400;1,500;1,600;1,700&family=Poppins:wght@400;500;600;700&display=swap);

body {
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* use viewport-relative units to cover page fully */
  height: 100vh;
}
html,
body,
#root,
#root > .ui-provider {
  height: 100%;
}
.ca-layout {
  min-height: 100%;
}
.ca-layout .ui-layout__main {
  background-color: #fff;
  padding: 20px 16px;
}
.ca-btn {
  height: 30px !important;
}
.ca-btn .ui-button__content {
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  text-transform: capitalize;
}
.ca-btn--radius {
  border-radius: 23px !important;
}
.ca-btn--primary {
  background-color: #201b51 !important;
}
.ca-dropdown .ui-dropdown__toggle-indicator {
  width: 32px;
  right: 0;
  height: 32px;
}
.ca-statusDropdown__dropdown-content {
  border-radius: 10px;
  position: absolute;
  width: 183px;
  box-sizing: border-box;
  text-align: left;
  padding: 6px 0;
  box-shadow: 0 0 10px 1px rgba(82, 76, 82, 0.2);
  background-color: white;
  z-index: 2;
  display: block;
  margin-top: 15px !important;
  margin-right: -10px;
}
.ca-statusDropdown__dropdown-item {
  padding: 8px 37px 8px 17px;
  position: relative;
  display: -webkit-flex;
  display: flex;
  width: 184px;
}
.ca-statusDropdown__dropdown-item:hover {
  background-color: #F2F2F2;
}
.ca-statusDropdown__group-label {
  font-size: 11px;
  line-height: 16px;
  display: inline-block;
  margin: 8px 0 8px 17px;
  color: #a8a8a8;
}
.ca-statusDropdown__separator {
  cursor: default;
  width: 90%;
  margin: 5px auto;
  height: 1px;
  content: " ";
  background-color: #d3d3d3;
}
.ca-statusDropdown__before-icon {
  position: relative;
  padding-right: 12px;
  top: 5%;
}
.ca-statusDropdown__after-icon {
  position: absolute;
  margin-left: 80%;
  top: 15%;
  color: #217AFF;
}
.ca-statusDropdown__after-icon-auto {
  position: absolute;
  margin-left: 5px;
  color: #217AFF;
}
.ca-statusDropdown__auto-tracking-item {
  padding-left: 5px;
  position: relative;
  width: 184px;
}
.ca-statusDropdown__auto-tracking-item:hover {
  background-color: #F2F2F2;
}
.ca-statusDropdown__button-status {
  color: white;
  min-width: 140px;
  height: 22px;
  line-height: 22px;
  margin-left: 0 !important;
  font-weight: 500;
  font-size: 12px !important;
  border-radius: 15px;
  padding: 0 10px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
}
.ca-statusDropdown__auto-tracking {
  color: #217AFF;
  text-transform: uppercase;
  font-weight: 600;
  text-align: left;
  font-size: 12px!important;
}
.ca-statusDropdown__not-start {
  background-color: #8c8c8c;
}
.ca-statusDropdown__overdue {
  background-color: #ff5858;
}
.ca-statusDropdown__on-track {
  background-color: #8cbc5b;
}
.ca-statusDropdown__behind {
  background-color: #ff9900;
}
.ca-statusDropdown__complete {
  background-color: #594bdb;
}
.ca-datepicker {
  width: 100% !important;
}
.ca-datepicker > span {
  display: block !important;
  position: fixed;
  margin-top: 32px;
  z-index: 1;
  width: 124px;
}
.ca-datepicker .react-date-picker__wrapper {
  color: #252423;
  background-color: #f5f5f5;
  border-radius: 0.2143rem 0.2143rem 0.1429rem 0.1429rem;
  border: none;
}
.ca-datepicker input:focus,
.ca-datepicker input:active {
  outline: none;
}
.ca-datepicker .react-calendar__tile--active {
  background-color: #6264a7;
}
.ca-datepicker .react-date-picker__inputGroup {
  height: 32px;
  padding: 0 0.8571rem;
}
.ca-textarea {
  min-height: 72px;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-right: 0.8571rem;
  background-color: #f3f2f1;
  padding-left: 0.8571rem;
  border: none;
  border-bottom: 0.1429rem solid transparent;
  border-radius: 0.2143rem 0.2143rem 0.1429rem 0.1429rem;
  width: 100%;
  outline-width: 0px;
}
.ca-textarea:focus {
  border-bottom-color: #6264a7;
}
.ca-textarea:disabled {
  color: #11100f;
}
.ca-loader--fluid {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
}
.ca-input {
  /* Firefox */
  /* Firefox */
}
.ca-input .ui-input__input {
  border-radius: 3px;
  border: 1px solid transparent;
}
.ca-input .ui-input__input:focus {
  border: 1px solid #594bdb;
}
.ca-input .ui-input__input:disabled {
  color: #11100f;
}
.ca-input--read-only {
  color: #11100f;
}
.ca-input input::-webkit-outer-spin-button,
.ca-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ca-input input[type="number"] {
  -moz-appearance: textfield;
}
.ca-input input::-webkit-outer-spin-button,
.ca-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ca-input input[type="number"] {
  -moz-appearance: textfield;
}
.ca-form-field {
  width: 100%;
}
.ca-form-field--read-only {
  display: inline-block;
  width: auto;
}
.ca-form-field__bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 3px;
}
.ca-form-field__actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-flex: 1 1;
          flex: 1 1;
}
.ca-form-field__accept {
  margin-right: 13px;
}
.ca-form-field__close {
  padding: 5px;
}
.ca-form-field__button {
  color: #594bdb;
  cursor: pointer;
  border-radius: 100px;
  background-color: #f2f2f2;
  width: 24px;
  height: 24px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.ca-page-title {
  font-size: 18px;
  color: #242424;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  margin-right: auto;
}
.ca-pagination {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.ca-pagination__page-text {
  color: #242424;
  font-size: 14px;
}
.ca-pagination__page-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.ca-pagination__page-button {
  width: 28px;
  height: 28px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #000;
  margin: 0 3px;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 3px;
}
.ca-pagination__page-button:focus {
  outline: none;
}
.ca-pagination__page-button-disabled {
  cursor: not-allowed;
  color: #dcdcdc;
}
.ca-pagination__page-button-disabled svg path:last-child {
  fill: #dcdcdc;
}
.ca-system-error {
  text-align: center;
}
.ca-system-error__image {
  text-align: center;
  max-width: 200px;
  margin: 0 auto;
}
.ca-system-error__title {
  color: #030303;
  font-size: 17px;
  font-weight: bold;
}
.ca-system-error__description {
  color: #030303;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 0;
}
.ca-tooltip__tooltip-container {
  font-size: 12px;
  color: #11101f;
  background: #fff;
  text-align: left;
  padding: 5px 10px;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
}
.ca-tooltip__tooltip-arrow {
  display: none;
}
.ca-top-bar__page-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.ca-top-bar__go-back-btn {
  margin-right: 20px;
}
.ca-goal-modal {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.ca-goal-modal .ui-dialog__header {
  margin-bottom: 0;
}
.ca-goal-modal .ui-header {
  width: 100%;
}
.ca-goal-modal__modal-content {
  overflow-y: auto;
  overflow-x: hidden;
}
.ca-goal-modal__header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  position: relative;
}
.ca-goal-modal__logo {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.ca-goal-modal__header-title {
  margin-left: 15px;
}
.ca-goal-modal__title {
  color: #242424;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  text-transform: capitalize;
}
.ca-goal-modal__subtitle {
  font-size: 12px;
  color: #616161;
  margin: 0;
}
.ca-goal-modal__close {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.ca-goal-modal .ui-dialog__content {
  margin-bottom: 1.5rem !important;
}
.ca-goal-modal .ui-dialog__footer {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  position: absolute;
  bottom: 0;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  height: 48px;
  z-index: 1;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 1rem;
  background: white;
}
.ca-sign-in-modal__content {
  margin-top: 20px;
}
.ca-sign-in-modal__lock {
  background-color: #201b51;
  width: 60px;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 50px;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 auto;
}
.ca-sign-in-modal__lock-icon {
  color: #fff;
}
.ca-sign-in-modal__title {
  text-align: center;
  margin-bottom: 0.8em;
  line-height: 1.1;
  letter-spacing: -0.03em;
  color: #3c3c3c;
  font-size: 2.3rem;
}
.ca-sign-in-modal__description {
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
}
.ca-sign-in-modal__sub-des {
  font-size: 18px;
  display: block;
  text-align: center;
  line-height: 40px;
}
.ca-sign-in-modal__link {
  margin-top: 40px;
  margin-bottom: 20px !important;
  font-size: 16px;
}
.ca-sign-in-modal__btn-submit {
  height: 50px !important;
  width: 120px;
}
.ca-sign-in-modal__btn-submit .ui-button__content {
  font-weight: bold;
  font-size: 16px;
}
.ca-sign-in-modal__input-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 20px 0;
  padding: 0 60px;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.ca-sign-in-modal__input {
  width: 200px;
  border-radius: 4px;
  padding: 5px;
  border-color: #ec9234;
  outline: 0;
}
.ca-sign-in-modal__input:focus,
.ca-sign-in-modal__input:hover {
  border-color: #ec9234;
}
.ca-sign-in-modal__domain {
  text-align: center;
  font-size: 18px;
  color: #201b51;
  line-height: 32px;
}
.ca-sign-in-modal .ant-modal-footer {
  border-top: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.ca-sign-in-modal__sign-up-text {
  font-size: 18px;
  margin-bottom: 16.5px;
  text-align: center;
  padding: 0px 55px;
}
.ca-sign-in-modal__sign-up-text a {
  color: #0052cc;
  text-decoration: none;
}
.ca-sign-in-modal__sign-up-text a:hover {
  text-decoration: underline;
}
.ca-sign-in-modal__error-message {
  color: #c4314b;
  margin: 0;
}
.ca-sign-in-modal__input-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.ca-goal-form {
  margin-top: 20px;
}
.ca-goal-form__submit {
  margin-top: 20px;
  text-align: right;
}
.ca-goal-form__link {
  margin-top: 0 !important;
}
.ca-goal-form__btn-link .ui-button__content {
  color: #201b51;
  font-size: 12px;
  font-weight: normal;
}
.ca-goal-form__million {
  color: #11100f;
  font-weight: normal;
  font-size: 14px;
  margin-left: 10px;
  margin-top: 8px;
}
.ca-goal-form__million-read-only {
  margin-top: 0;
  margin-left: 2px;
}
.ca-goal-form .ca-goal-status {
  max-width: 96px;
}
.ca-goal-form__progress {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.ca-goal-form__status {
  width: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.ca-goal-form__percent {
  color: #8cbc5b;
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
}
.ca-goal-form__focus-area-select {
  max-width: 50%;
}
@media (max-width: 480px) {
  .ca-goal-form__flex-responsive {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .ca-goal-form__flex-responsive:not(:last-child) {
    margin-bottom: 15px !important;
  }
  .ca-goal-form__flex-responsive .ui-flex {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .ca-goal-form__flex-responsive .ui-form__field {
    width: 100%;
    -webkit-flex-basis: auto;
            flex-basis: auto;
  }
  .ca-goal-form__flex-responsive .ui-form__field:not(:last-child) {
    margin-bottom: 15px !important;
  }
}
.ca-goal-status {
  height: 30px;
  color: #fff;
  font-weight: bold;
  border-radius: 23px;
  text-align: center;
  min-width: 96px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 10px;
  text-transform: capitalize;
  font-size: 11px;
  max-width: 120px!important;
}
.ca-goal-status--not-start {
  background-color: #8c8c8c;
}
.ca-goal-status--overdue {
  background-color: #ff5858;
}
.ca-goal-status--on-track {
  background-color: #8cbc5b;
}
.ca-goal-status--behind {
  background-color: #ff9900;
}
.ca-goal-status--complete {
  background-color: #594bdb;
}
.ca-goal-status--within-threshold {
  background-color: #594bdb;
}
.ca-goal-status--manual {
  background-color: #404040;
}
.ca-goal-status__with-pointer {
  cursor: pointer;
}
.ca-goal-status__before-icon {
  margin-right: 10px;
  top: 5%;
  left: 0px;
}
.ca-goal-status__after-icon {
  margin-left: 8px;
  margin-right: 3px;
  margin-top: -3px;
}
.ca-goal-type {
  font-weight: 600;
  font-size: 11px;
  color: #202040;
}
.ca-owner {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background-color: #594bdb;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.ca-owner__text {
  font-family: 'Poppins', sans-serif;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
}
.ca-goals {
  height: 100%;
}
.ca-goals__page-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.ca-goals__filters-container {
  margin-top: 12px;
  margin-bottom: 16px;
}
.ca-goals .ui-table__header .ui-table__cell__content {
  font-family: 'Poppins', sans-serif;
  color: #201b50;
  font-size: 11px;
  text-transform: capitalize;
}
.ca-goals .ui-table__header .ui-table__cell::before {
  display: none;
}
.ca-goals .ui-table__cell::before {
  width: 3px;
  background-color: #dfdfdf;
  content: '';
  height: 30px;
  position: absolute;
  left: -2px;
  border-radius: 5px;
}
.ca-goals__goal-row {
  background-color: #f2f2f2 !important;
  border-radius: 5px 10px 10px 5px;
  height: 45px !important;
  margin: 4px 0;
  position: relative;
}
.ca-goals__goal-row::before {
  content: '';
  width: 7px;
  border-radius: 5px;
  height: 100%;
  position: absolute;
  left: 0;
}
.ca-goals__not-start::before {
  background-color: #8c8c8c;
}
.ca-goals__overdue::before {
  background-color: #ff5858;
}
.ca-goals__on-track::before {
  background-color: #8cbc5b;
}
.ca-goals__behind::before {
  background-color: #ff9900;
}
.ca-goals__complete::before {
  background-color: #594bdb;
}
.ca-goals__within-threshold::before {
  background-color: #594bdb;
}
.ca-goals__manual::before {
  background-color: #404040;
}
.ca-goals__title {
  color: #201b50;
  font-size: 11px;
  display: block;
  text-align: left;
  cursor: pointer;
  padding-left: 11px;
}
.ca-goals__title:hover {
  color: #594bdb;
}
.ca-goals__text {
  font-size: 11px;
  display: block;
  font-weight: 600;
  color: #201b50;
}
.ca-goals__width-title::before {
  display: none;
}
.ca-goals__width-type {
  max-width: 97px !important;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}
.ca-goals__width-owner {
  max-width: 70px !important;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}
.ca-goals__width-date {
  max-width: 120px !important;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}
.ca-goals__width-status {
  max-width: 138px !important;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}
.ca-goals__width-weight {
  max-width: 80px !important;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}
.ca-goals__table-header-title {
  margin-top: 8px;
  margin-bottom: 8px;
}
.ca-goals__table-header-title-mobile {
  cursor: pointer;
}
.ca-goals__table-header-image-container {
  min-height: 24px;
  min-width: 24px;
}
.ca-goals__table-header-image {
  display: block !important;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.ca-goals__btn-sign-out .ui-button__content {
  text-transform: none;
  text-transform: initial;
}
.ca-goal-confirmation {
  min-height: 400px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.ca-goal-confirmation__text {
  font-size: 16px;
  color: #616161;
  text-align: center;
}
.ca-goal-confirmation__success-image {
  margin-bottom: 20px;
}
.ca-goal-confirmation__link {
  margin-top: 10px !important;
}
.ca-goal-confirmation__btn-link .ui-button__content {
  color: #201b51;
  font-size: 12px;
  font-weight: normal;
}
.ca-plans {
  height: 100%;
  overflow: hidden;
}
.ca-plans__plan-title {
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  color: #201b50;
  padding: 25px 15px 5px 40px;
}
.ca-plans__filters-container {
  margin-top: 40px;
}
.ca-plan {
  height: 100%;
}
.ca-focus-area__title {
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  color: #242424;
  font-family: 'Poppins', sans-serif;
  color: #201b50;
  padding: 25px 0px 5px 0px;
  line-height: 1;
}
.ca-focus-area--hidden {
  display: none;
}
.ca-focus-area--show {
  display: block;
}
.ca-feed__tabs {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 24px;
  border-bottom: 1px solid #CECECE;
}
.ca-feed__tabs > p {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #201B50;
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.ca-feed__tabs .addButton {
  padding-right: 5px;
  border-radius: 4px;
  border: 1px solid #594BDB;
  height: 24px;
  -webkit-appearance: none;
  display: -webkit-flex;
  display: flex;
}
.ca-feed__tabs .addButton span {
  font-size: 20px;
  color: #594BDB;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.ca-feed__tabs .addButton select {
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 5px;
  width: 75px;
  background: transparent;
  color: #594BDB;
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.ca-feed__tabs .tabs {
  display: -webkit-flex;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}
.ca-feed__tabs .tabs .tabItem {
  padding: 4px 8px;
  text-transform: capitalize;
  cursor: pointer;
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #858585;
  transition: all 0.3s;
}
.ca-feed__tabs .tabs .tabItem.active {
  background-color: #E3E3E3;
  border-radius: 4px 4px 0px 0px;
  color: #201B50;
}
.ca-feed__feedlist {
  margin-top: 11px;
  max-height: 200px;
  overflow: auto;
}
.ca-feed__feedlist .listitem {
  border-top: 1px solid #C0C0C0;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.ca-feed__feedlist .listitem .single-update {
  background: inherit;
  transition: all 0.2s;
}
.ca-feed__feedlist .listitem .editIcon {
  display: none;
  transition: all 0.2s;
}
.ca-feed__feedlist .listitem .single-update:hover {
  background: #F2F2F2;
}
.ca-feed__feedlist .listitem .single-update:hover .editIcon {
  display: block;
}
.ca-feed__feedlist .listitem .date {
  font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 10px;
  color: #484378;
  position: absolute;
  top: -5px;
  left: calc(50% - 2rem);
  background: white;
  padding: 0 1em;
}
.ca-feed__feedlist .listitem .content {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 11px 4px;
}
.ca-feed__feedlist .listitem .content .name {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #201B50;
  margin: 0;
}
.ca-feed__feedlist .listitem .content .name .template {
  color: #C0C0C0;
  padding-left: 0.5rem;
}
.ca-feed__feedlist .listitem .content .comment {
  font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #484378;
  padding: 0 0 0 4px;
  margin-bottom: 10px;
}
.ca-feed__feedlist .listitem .content .customAttribute {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 8px 0 0 4px;
}
.ca-feed__feedlist .listitem .content .customAttribute p {
  margin: 0;
  padding: 0;
  font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
}
.ca-feed__feedlist .listitem .content .customAttribute span {
  color: #484378;
  font-size: 12px;
}
.ca-feed__feedlist .listitem .content .update {
  padding: 8px;
}
.ca-feed__feedlist .listitem .content .update .question-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 2px;
  gap: 2px;
}
.ca-feed__feedlist .listitem .content .update .question-group .question {
  margin: 0;
  font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: #3C3C3C;
}
.ca-feed__feedlist .listitem .content .update .question-group .answer {
  margin: 0;
  font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #484378;
}
.ca-feed__feedlist .listitem .sideBox {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.ca-feed__feedlist .listitem .sideBox svg {
  cursor: pointer;
}
.ca-feed__feedlist .listitem .sideBox .time {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 10px;
  padding: 0;
  margin: 0;
  color: #201B50;
}
.ui-radiogroup__item__indicator {
  border: none !important;
}
.ca-feed__feedinput {
  position: relative;
  background: white;
  border-radius: 4px;
}
.ca-feed__feedinput > p {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #201B50;
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.ca-feed__feedinput .dynamic-inputs {
  background: #f2f2f2;
  padding: 8px;
}
.ca-feed__feedinput .input-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.ca-feed__feedinput .input-container p {
  width: 100%;
}
.ca-feed__feedinput .input-container input,
.ca-feed__feedinput .input-container select {
  border: 2px solid #CECECE;
  border-radius: 4px;
  height: 32px;
  background: white;
}
.ca-feed__feedinput .input-container select {
  width: 260px;
}
.ca-feed__feedinput .checkbox label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 12px;
  gap: 12px;
  position: relative;
  cursor: pointer;
}
.ca-feed__feedinput .checkbox label .checkmark {
  position: absolute;
  top: 9px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: white;
  border: 2px #CECECE;
  border-radius: 2px;
}
.ca-feed__feedinput .checkbox label .checkmark:after {
  left: 5px;
  top: 1px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  content: "";
  position: absolute;
}
.ca-feed__feedinput .checkbox label input:checked ~ .checkmark {
  background-color: #201B50;
}
.ca-feed__feedinput .radio label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 12px;
  gap: 12px;
  position: relative;
  cursor: pointer;
}
.ca-feed__feedinput .radio label .checkmark {
  position: absolute;
  top: 9px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: white;
  border-radius: 50%;
  border: 2px #CECECE;
}
.ca-feed__feedinput .radio label .checkmark:after {
  top: 5px;
  left: 5px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: white;
  content: '';
  display: block;
  position: relative;
}
.ca-feed__feedinput .radio label input:checked ~ .checkmark {
  background-color: #201B50;
}
.ca-feed__feedinput .date input {
  width: 142px;
}
.ca-feed__feedinput .custom-file-uploader {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  margin: 8px 0;
  background: white;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  transition: all 0.2s;
  color: #3C3C3C;
}
.ca-feed__feedinput .custom-file-uploader:hover {
  color: white;
  background: #201B50;
}
.ca-feed__feedinput .custom-file-uploader input {
  display: none;
}
.ca-feed__feedinput .buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  bottom: 8px;
  right: 5px;
}
.ca-feed__feedinput .buttons .ui-button {
  background: none;
  border: none;
  box-shadow: none;
  padding: 8px;
  margin: 20px 0 0 0;
  min-width: auto;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #594BDB;
}
.ca-feed__feedinput .buttons .ui-button:disabled {
  color: silver;
}
.ca-welcome-screen__title {
  margin-top: 20px;
  margin-bottom: 8px;
  color: #030303;
  font-weight: bold;
  font-size: 17px;
}
.ca-welcome-screen__subtitle {
  color: #030303;
  font-size: 14px;
  margin-bottom: 16.5px;
}
.ca-welcome-screen__btn-sign-in .ui-button__content {
  text-transform: inherit;
}
.ca-welcome-screen__sign-up-text {
  color: #030303;
  font-size: 14px;
  margin-bottom: 16.5px;
  margin-top: 0;
  text-align: center;
}
.ca-welcome-screen__sign-up-text a {
  color: #0052cc;
  text-decoration: none;
}
.ca-welcome-screen__sign-up-text a:hover {
  text-decoration: underline;
}
.ca-bot-auth-popup__sign-in-modal {
  box-shadow: none !important;
  width: 100% !important;
  height: 100% !important;
}
.ca-goal-create__goal-modal {
  box-shadow: none !important;
  width: 100% !important;
  height: 100% !important;
  padding-top: 0 !important;
}
.ca-goal-create__goal-modal .ui-dialog__header {
  display: none;
}
@media (max-width: 991px) {
  .ca-sign-in-modal {
    min-width: 500px;
  }
}
@media (min-width: 767px) {
  .ca-goal-modal {
    min-width: 650px !important;
  }
}
@media (max-width: 767px) {
  .ca-modal {
    padding: 1rem !important;
  }
  .ca-goal-modal {
    width: 90% !important;
  }
}
@media (max-width: 480px) {
  .ca-layout .ui-layout__main {
    padding: 10px;
  }
  .ui-dialog__overlay {
    max-width: 100%;
    overflow-x: hidden !important;
  }
  .ca-goals .ui-table__cell {
    padding: 5px !important;
  }
  .ca-goals__goal-row {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .ui-layout__main {
    padding: 10px;
  }
  .ca-goals__title,
  .ca-goal-type,
  .ca-goals__text,
  .ca-goal-status {
    font-size: 9px;
  }
  .ca-goals__title {
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ca-goal-status {
    max-width: 100%;
    min-width: 55px;
    margin: 0 5px;
    padding: 0 2px;
  }
  .ca-goals__page-header {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .ca-goals__page-header .ca-btn {
    min-width: auto;
    width: 70px;
    padding: 0 5px !important;
  }
  .ca-goals__page-header .ca-btn .ui-box {
    margin-right: 2px;
  }
  .ca-goals__page-header .ca-btn .ui-button__content {
    font-size: 9px;
  }
  .ca-goal-form__focus-area-select {
    max-width: 100%;
  }
}

