.ca-top-bar {
  &__page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__go-back-btn {
    margin-right: 20px;
  }
}
