.ca-system-error {
  text-align: center;
  &__image {
    text-align: center;
    max-width: 200px;
    margin: 0 auto;
  }
  &__title {
    color: #030303;
    font-size: 17px;
    font-weight: bold;
  }
  &__description {
    color: #030303;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 0;
  }
}
