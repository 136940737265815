body {
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* use viewport-relative units to cover page fully */
  height: 100vh;
}
html,
body,
#root,
#root > .ui-provider {
  height: 100%;
}
