.ca-textarea {
  min-height: 72px;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-right: 0.8571rem;
  background-color: rgb(243, 242, 241);
  padding-left: 0.8571rem;
  border: none;
  border-bottom: 0.1429rem solid transparent;
  border-radius: 0.2143rem 0.2143rem 0.1429rem 0.1429rem;
  width: 100%;
  outline-width: 0px;
  &:focus {
    border-bottom-color: rgb(98, 100, 167);
  }
  &:disabled {
    color: rgb(17, 16, 15);
  }
}
