
.ca-statusDropdown {
    
    &__dropdown-content {
        border-radius: 10px;
        position: absolute;
        width: 183px;
        box-sizing: border-box;
        text-align: left;
        padding: 6px 0;
        box-shadow: 0 0 10px 1px rgba(82, 76, 82, 0.2);
        background-color: white;
        z-index: 2;
        display: block;
        margin-top: 15px !important;
        margin-right: -10px;

    }
    &__dropdown-item {
        padding: 8px 37px 8px 17px;
        position: relative;
        display: flex;
        width: 184px;
        &:hover{
            background-color: #F2F2F2;
        }

    }
    &__group-label{
        font-size: 11px;
        line-height: 16px;
        display: inline-block;
        margin: 8px 0 8px 17px;
        color: #a8a8a8;
    }
    &__separator{
        cursor: default;
        width: 90%;
        margin: 5px auto;
        height: 1px;
        content: " ";
        background-color: #d3d3d3;
    }
    &__before-icon{
        position: relative;
        padding-right: 12px;
        top: 5%;
    }
    &__after-icon{
        position: absolute;
        margin-left: 80%;
        top: 15%;
        color: #217AFF;
    }
    &__after-icon-auto{
        position: absolute;
        margin-left: 5px;
        
        color: #217AFF;
    }
    &__auto-tracking-item{
        padding-left: 5px;
        position: relative;
        width: 184px;
        &:hover{
            background-color: #F2F2F2;
        }
        
    }
    &__button-status{
        color: white;
        min-width: 140px;
        height: 22px;
        line-height: 22px;
        margin-left: 0 !important;
        font-weight: 500;
        font-size: 12px !important;
        border-radius: 15px;
        padding: 0 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
        cursor: pointer;
        
    }
    &__auto-tracking {
        
        color: #217AFF;
        text-transform: uppercase;
        font-weight: 600;
        text-align: left;
        font-size: 12px!important;

        
      
    }
    &__not-start {
        
          background-color: @statusNotStarted;
        
      }
      &__overdue {
        
          background-color: @statusOverdue;
        
      }
      &__on-track {
        
          background-color: @statusOnTrack;
        
      }
      &__behind {
        
          background-color: @statusBehind;
        
      }
      &__complete {
        
          background-color: @statusComplete;
        
      }
    
}