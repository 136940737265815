.ca-sign-in-modal {
  &__content {
    margin-top: 20px;
  }
  &__lock {
    background-color: @primaryColor;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    justify-content: center;
    margin: 0 auto;
  }
  &__lock-icon {
    color: #fff;
  }
  &__title {
    text-align: center;
    margin-bottom: 0.8em;
    line-height: 1.1;
    letter-spacing: -0.03em;
    color: #3c3c3c;
    font-size: 2.3rem;
  }
  &__description {
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
  }
  &__sub-des {
    font-size: 18px;
    display: block;
    text-align: center;
    line-height: 40px;
  }
  &__link {
    margin-top: 40px;
    margin-bottom: 20px !important;
    font-size: 16px;
  }
  &__btn-submit {
    height: 50px !important;
    width: 120px;
    .ui-button__content {
      font-weight: bold;
      font-size: 16px;
    }
  }
  &__input-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 20px 0;
    padding: 0 60px;
    flex-direction: row;
  }
  &__input {
    width: 200px;
    border-radius: 4px;
    padding: 5px;
    border-color: #ec9234;
    outline: 0;
    &:focus,
    &:hover {
      border-color: #ec9234;
    }
  }
  &__domain {
    text-align: center;
    font-size: 18px;
    color: @primaryColor;
    line-height: 32px;
  }
  .ant-modal-footer {
    border-top: none;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__sign-up-text {
    font-size: 18px;
    margin-bottom: 16.5px;
    text-align: center;
    padding: 0px 55px;
    a {
      color: #0052cc;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__error-message {
    color: rgb(196, 49, 75);
    margin: 0;
  }
  &__input-content {
    display: flex;
    flex-direction: column;
  }
}
