@media @desktop-down {
  .ca-sign-in-modal {
    min-width: 500px;
  }
}
@media @tablet-up {
  .ca-goal-modal {
    min-width: 650px !important;
  }
}
@media @tablet-down {
  .ca-modal {
    padding: 1rem !important;
  }
  .ca-goal-modal {
    width: 90% !important;
    // max-height: calc((var(--vh, 1vh) * 90));
  }
}

@media @phone-down {
  .ca-layout {
    .ui-layout__main {
      padding: 10px;
    }
  }
  .ui-dialog__overlay {
    max-width: 100%;
    overflow-x: hidden !important;
  }
  .ca-goals {
    .ui-table__cell {
      padding: 5px !important;
    }
    &__goal-row {
      flex-wrap: wrap;
    }
  }
  .ui-layout__main {
    padding: 10px;
  }
  .ca-goals__title,
  .ca-goal-type,
  .ca-goals__text,
  .ca-goal-status {
    font-size: 9px;
  }
  .ca-goals__title {
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ca-goal-status {
    max-width: 100%;
    min-width: 55px;
    margin: 0 5px;
    padding: 0 2px;
  }
  .ca-goals__page-header {
    flex-direction: column;
    .ca-btn {
      min-width: auto;
      width: 70px;
      padding: 0 5px !important;
      .ui-box {
        margin-right: 2px;
      }
      .ui-button__content {
        font-size: 9px;
      }
    }
  }
  .ca-goal-form__focus-area-select {
    max-width: 100%;
  }
}
