.ca-goal-form {
  margin-top: 20px;
  &__submit {
    margin-top: 20px;
    text-align: right;
  }
  &__link {
    margin-top: 0 !important;
  }
  &__btn-link {
    .ui-button__content {
      color: #201b51;
      font-size: 12px;
      font-weight: normal;
    }
  }
  &__million {
    color: rgb(17, 16, 15);
    font-weight: normal;
    font-size: 14px;
    margin-left: 10px;
    margin-top: 8px;
  }
  &__million-read-only {
    margin-top: 0;
    margin-left: 2px;
  }
  .ca-goal-status {
    max-width: 96px;
  }
  &__progress {
    display: flex;
    align-items: flex-start;
  }
  &__status {
    width: 80%;
    display: flex;
    align-items: center;
  }
  &__percent {
    color: #8cbc5b;
    font-size: 14px;
    font-weight: 600;
    margin-left: 15px;
  }
  &__focus-area-select {
    max-width: 50%;
  }
  @media @phone-down {
    &__flex-responsive {
      flex-direction: column;
      &:not(:last-child) {
        margin-bottom: 15px !important;
      }
      .ui-flex {
        flex-direction: column;
      }
      .ui-form__field {
        width: 100%;
        flex-basis: auto;
        &:not(:last-child) {
          margin-bottom: 15px !important;
        }
      }
    }
  }
}
