.ca-plans {
  height: 100%;
  overflow: hidden;
  &__plan-title {
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: #201b50;
    padding: 25px 15px 5px 40px;
  }
  &__filters-container {
    margin-top: 40px;
  }
}
