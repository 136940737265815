.ca-goal-confirmation {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__text {
    font-size: 16px;
    color: #616161;
    text-align: center;
  }
  &__success-image {
    margin-bottom: 20px;
  }
  &__link {
    margin-top: 10px !important;
  }
  &__btn-link {
    .ui-button__content {
      color: #201b51;
      font-size: 12px;
      font-weight: normal;
    }
  }
}
