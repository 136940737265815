.ca-goal-modal {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .ui-dialog__header {
    margin-bottom: 0;
  }
  .ui-header {
    width: 100%;
  }
  &__modal-content {
    overflow-y: auto;
    overflow-x: hidden;
  }
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }
  &__logo {
    display: flex;
    align-items: center;
  }
  &__header-title {
    margin-left: 15px;
  }
  &__title {
    color: #242424;
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    text-transform: capitalize;
  }
  &__subtitle {
    font-size: 12px;
    color: #616161;
    margin: 0;
  }
  &__close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  .ui-dialog__content {
    margin-bottom: 1.5rem !important;
    // max-height: 900px;
    // overflow-y: auto;
    // overflow-x: hidden;
  }
  .ui-dialog__footer {
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
    height: 48px;
    z-index: 1;
    align-items: center;
    padding-left: 1rem;
    background: white;
  }
}
