.ca-tooltip {
  &__tooltip-container {
    font-size: 12px;
    color: #11101f;
    background: #fff;
    text-align: left;
    padding: 5px 10px;
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  }
  &__tooltip-arrow {
    display: none;
  }
}
