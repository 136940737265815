.ca-goal-status {

  &--not-start {
    background-color: @statusNotStarted;
  }
  &--overdue {
    background-color: @statusOverdue;
  }
  &--on-track {
    background-color: @statusOnTrack;
  }
  &--behind {
    background-color: @statusBehind;
  }
  &--complete {
    background-color: @statusComplete;
    
  }
  &--within-threshold {
    background-color: @statusWithinThreshold;
  }
  &--manual {
    background-color: @statusManual;
  }
  &__with-pointer {
    cursor: pointer;
    
  }
  
  &__before-icon {
    margin-right: 10px;
    
    top: 5%;
    left: 0px;
  }
  
  &__after-icon {
    margin-left: 8px;
    margin-right: 3px;
    
    margin-top:-3px;
  }
  height: 30px;
  color: #fff;
  font-weight: bold;
  border-radius: 23px;
  text-align: center;
  min-width: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  text-transform: capitalize;
  font-size: 11px;
  max-width: 120px!important;
  
}
