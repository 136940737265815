.ca-welcome-screen {
  &__title {
    margin-top: 20px;
    margin-bottom: 8px;
    color: #030303;
    font-weight: bold;
    font-size: 17px;
  }
  &__subtitle {
    color: #030303;
    font-size: 14px;
    margin-bottom: 16.5px;
  }
  &__btn-sign-in {
    .ui-button__content {
      text-transform: inherit;
    }
  }
  &__sign-up-text {
    color: #030303;
    font-size: 14px;
    margin-bottom: 16.5px;
    margin-top: 0;
    text-align: center;
    a {
      color: #0052cc;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
