.ca-datepicker {
  > span {
    display: block !important;
    position: fixed;
    margin-top: 32px;
    z-index: 1;
    width: 124px;
  }
  width: 100% !important;
  .react-date-picker__wrapper {
    color: rgb(37, 36, 35);
    background-color: rgb(245, 245, 245);
    border-radius: 0.2143rem 0.2143rem 0.1429rem 0.1429rem;
    border: none;
  }
  input {
    &:focus,
    &:active {
      outline: none;
    }
  }
  .react-calendar__tile--active {
    background-color: rgb(98, 100, 167);
  }
  .react-date-picker__inputGroup {
    height: 32px;
    padding: 0 0.8571rem;
  }
}
