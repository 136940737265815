.ca-btn {
  height: 30px !important;
  .ui-button__content {
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    text-transform: capitalize;
  }
  &--radius {
    border-radius: 23px !important;
  }
  &--primary {
    background-color: @primaryColor !important;
  }
}
