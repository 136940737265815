.ca-input {
  .ui-input__input {
    border-radius: 3px;
    border: 1px solid transparent;
    &:focus {
      border: 1px solid #594bdb;
    }
    &:disabled {
      color: rgb(17, 16, 15);
    }
  }
  &--read-only {
    color: rgb(17, 16, 15);
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
